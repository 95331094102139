::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder-color;
    font-family: $font;
    font-weight: 500;
    opacity: 0.6;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder-color;
    font-family: $font;
    font-weight: 500;
    opacity: 0.6;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder-color;
    font-family: $font;
    font-weight: 500;
    opacity: 0.6;
}
:-moz-placeholder { /* Firefox 18- */
    color: $placeholder-color;
    font-family: $font;
    font-weight: 500;
    opacity: 0.6;
}
input, input:focus{
    border: none;
    outline: none;
    padding: 0;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
}
input::placeholder, textarea::placeholder {
    color: $placeholder-color;
    font-family: $font;
    opacity: 0.6;
}

input[type="text"], input[type="password"],input[type="email"], input[type="tel"], input[type="number"], select{
    width: 100%;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 20px;
    border: 1px solid $light-yellow;
    transition: all .2s;
    &.error{
        border-color: $red;
    }
    &:last-child{
        margin-bottom: 0;
    }
    &.w-auto{
        width: auto;
    }
    &.login {
        border-radius: 25px;
        border: none;
    }
    &.curve {
        border-radius: 25px;
        border: none;
    }
}
input[type="radio"], input[type="checkbox"]{
    &.error{
        border-color: $red;
    }
}

input[type=checkbox], input[type=radio], select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
}

input[type=checkbox] {
    height: 20px;
    border-radius: 4px;
    width: 20px;
    background: transparent;
    border: 3px solid $font-color;
}

input[type=radio] {
    height: 20px;
    border-radius: 8px;
    width: 20px;
    background: transparent;
    border: 3px solid $font-color;
}

input[type="checkbox"]:checked {
    background: $primary-color;
    position: relative;
    border: none;
    &:before {
        font-family: "fontAwesome";
        content: "\f00c";
        font-size: 12px;
        display: inline-block;
        color: $white;
        top: 50%;
        text-align: center;
        width: 100%;
        margin-top: -6px;
        line-height: 12px;
        position: absolute;
    }
}
input[type="radio"]:checked {
    background: $primary-color;
    position: relative;
    border: none;
    &:before {
        content: "";
        top: 1px;
        left: 1px;
        width: 18px;
        height: 18px;
        position: absolute;
        border: 1px solid white;
        border-radius: 7px;
    }
}
strong, b{
    font-weight: bolder;
}
.input-group{
    margin-bottom: 10px;
    .inputs{
        width: 100%;
    }
    label, p{
        display: block;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: $font-color;
        a{
            color: $font-color;
            &.cgu{
                span{
                    text-decoration: underline;
                    font-weight: 700;
                }
            }
        }
    }
    label{
        cursor: pointer;
    }
}
.align-checkbox{
    position: relative;
    display: flex;
    align-items: flex-start;
    input{
        cursor: pointer;
    }
    label{
        margin-left: 10px;
        cursor: pointer;
    }
    .input-error{
        position: absolute;
        top: -14px;
        left: 0;
    }
    @include media-breakpoint-down(sm){
        .input-error{
            position: relative;
            top: 0;
        }
    }
}
.input-error{
    color: $red;
    font-size: 12px;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
}
label.error {
    color: $red;
}

.select-container {
    position: relative;
    select {
        background-color: white;
    }
    &::after {
        position: absolute;
        font-family: 'fontAwesome';
        content: '\f078';
        font-size: 16px;
        display: inline-block;        
        right: 12px;
        top: 13px;
    }
}