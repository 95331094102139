.custom-slick-arrows {
    .custom-prev-button, .custom-next-button {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 9999px;
        line-height: 50px;
        background-color: $green;
        text-align: center;
        background-repeat: no-repeat;
        background-position: center;
        &:after {
            display: block;
            font-size: 20px;
        }
    }
    .custom-prev-button  {
        float: left;
        margin-right: 10px;
        background-image: url('../img/icons/ic-arrow-left.png');
    }
    .custom-next-button  {
        float: right;
        margin-left: 10px;
        background-image: url('../img/icons/ic-arrow-right.png');
    }
}
