#news {
    header {
        background-color: transparent;
        .site-logo {
            color: $white;
        }
        .site-menus {
            color: $white;
            @include media-breakpoint-down(sm){
                color: $purple;
            }
        }
        .container {
            border-color: rgba(255, 255, 255, 0.1);   
        }
        .burger-menu{
            color: $white;
        }
    }
}