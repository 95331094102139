#about, #our-values {
    header {
        background-color: $primary-color;
        .site-logo {
            color: $white;
        }
        .site-menus {
            color: $white;
            @include media-breakpoint-down(sm){
                color: $purple;
            }
        }
        .burger-menu{
            color: $white;
        }
        .container {
            border-color: rgba(255, 255, 255, 0.1);   
        }
    }
    .container {
        .font-medium {
            position: relative;
            &::before, &::after {
                font-style: normal;
                color: $light-black;
            }
            &::before {
                top: -20px;
            }
            &::after {
                top: unset;
                bottom: -115px;
            }
        }
    }
}    