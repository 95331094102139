$enable-grid-classes: true;
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-row-columns: 6;

$container-max-widths: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
	) !default;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
	) !default;

// Colors
$grey: #d8d8d8;
$purple: #544680;
$bg-color: #fcf4ec;
$green: #4dd99d;
$white: #ffff;
$light-yellow: #f7dab1;
$light-black: #3d3066;
$red: #e3342f;

$primary-color: $purple;
$secondary-color: $green;
$font-color: $purple;
$placeholder-color: $purple;


// Fonts
$font: 'Poppins';
$font-hepta-slab: 'Hepta Slab';

// Font Icons
$icomoon-font-path: "/fonts/icons" !default;
$icomoon-font-family: "icons" !default;

$icon-menu: "\e918";
$icon-arrow-right: "\e911";
$icon-arrow-left: "\e92a";
$icon-close: "\e916";
$icon-play: "\e915";
$icon-carret-down: "\e910";
