body {
    font-family: $font, sans-serif;
    overflow-x: hidden;
    background: #ffffff;
    color: $font-color;
}
h1, h2, h3, p{
    margin: 0;
}
ol, ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
blockquote, q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
hr{
    border-color: $grey;
}
html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a, a:active, a:hover, a:focus{
    text-decoration: none;
}
label{
    margin-bottom: 0;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes navbar-animate {
    0% {top: -80px;}
    100% {top: 0;}
}

@keyframes navbar-animate-back {
    0% {top: 0;}
    100% {top: -80px;}
}

.container {
    padding-left: 30px;
    padding-right: 30px;
}

.errors-container, .success-container{
    display: none;
    margin-bottom: 10px;
}
.invalid-feedback, .success-feedback{
    font-size: 16px;
    font-family: $font, sans-serif;;
    display: inline-block;
    width: 100%;
}

.invalid-feedback{
    color: $red;
}

.success-feedback{
    color: green;
}

.loading{
    &:before{
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #ffffffa6;
        position: absolute;
        border-radius: 8px;
        z-index: 1;
    }
    &:after{
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
        border: 8px solid transparent;
        border-radius: 50%;
        border-top: 8px solid $purple;
        border-bottom: 8px solid $purple;
        width: 50px;
        height: 50px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    &-no-opacity{
        &:before{
            background-color: #ffffff;
        }
    }
}

button, a {
    &:focus {
        outline: 0;
    }
}

button.loading, a.loading{
    position: relative;
    &:after{
        margin-top: -15px;
        width: 30px;
        height: 30px;
    }
}

*{
    &::-webkit-scrollbar-thumb {
        background: #000000;
        border-radius: 4px;
    }
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-ms-scrollbar-thumb {
        background: #000000;
        border-radius: 4px;
    }
    &::-ms-scrollbar {
        width: 4px;
    }
    &::-moz-scrollbar-thumb {
        background: #000000;
        border-radius: 4px;
    }
    &::-moz-scrollbar {
        width: 4px;
    }
}