.dropdown-wrapper{
    display: inline-block;
    position: relative;
}
.dropdown{
    top: 100%;
    padding: 16px;
    border-radius: 4px;
    z-index: 1050;
    line-height: 24px;
    background: white;
    position: absolute;
    color: #000000; 
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
    min-width: 100%;
    a{
        color: #000000;
    }
    &-right-anchor{
        right: 0;
    }
    &-top-anchor{
        top: auto;
        bottom: 100%;
    }
    &-fixed{
        min-width: initial;
    }
    @include media-breakpoint-down(sm){
        position: fixed;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100vh;
        margin-top: 0;
        border-radius: 0;
    }
    li{
        cursor: pointer;
    }
}