footer {
    .site-logo {
        margin-right: 60px;
        a {
            font-family: $font-hepta-slab, sans-serif;
            font-size: 24px;
            font-weight: 800;
            display: flex;
            img {
                margin-right: 15px;
            }
        }
    }
}

#reading-committes-modal {
    position: fixed;
    bottom: 40px;
    width: 960px;
    left: 50%;
    border-radius: 50px;
    padding: 20px 30px;
    transform: translateX(-50%);
    z-index: 22;
    
    @include media-breakpoint-down(md){
        display: none !important;
    }
}