.svg-icon {
    display: block;
    background-position: center;
    background-size: cover;
    width: 24px;
    height: 24px;
}

.social-icon {
    display: block;
    background-position: center;
    background-size: cover;
    width: 32px;
    height: 32px;
    &.ic-facebook {
        background-image: url('../img/icons/ic-fb.png');
    }
    &.ic-twitter {
        background-image: url('../img/icons/ic-twitter.png');
    }
    &.ic-instagram {
        background-image: url('../img/icons/ic-instagram.png');
    }
}