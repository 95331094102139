@import url('https://fonts.googleapis.com/css2?family=Hepta+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xvt586');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xvt586#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xvt586') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xvt586') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xvt586##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-size: 24px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-menu {
    &:before {
        content: $icon-menu; 
    }
}
.icon-arrow-left {
    &:before {
        content: $icon-arrow-left; 
    }
}
.icon-arrow-right {
    &:before {
        content: $icon-arrow-right; 
    }
}
.icon-close {
    &:before {
        content: $icon-close; 
    }
}
