#home{
    #home-landing {
        position: relative;
        background: $bg-color;
        &:after, &:before {
            content: '';
            position: absolute;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 1;
        }
        &:after {
            width: 495px;
            height: 678px;
            right: 0;
            bottom: -200px;
            background-image: url('../img/img-hand-pen.png');
        }

        &:before {
            width: 1200px;
            height: 320px;
            left: -1100px;
            top: 50px;
            background-image: url('../img/img-abstract-rectangle.png');
        }
        .container {
            position: relative;
            z-index: 2;
        }

        @include media-breakpoint-down(md){
            &:after {
                width: 30vw;
                height: calc(30vw * 1.3);
                background-image: url('../img/img-hand-pen-mobile.png');
                bottom: -100px;
            }
        }

        @include media-breakpoint-down(xs){
            padding-bottom: 300px;
            &:after {
                width: 80vw;
                height: calc(80vw * 1.3);
                background-image: url('../img/img-hand-pen-mobile.png');
                bottom: -100px;
            }
        }
    }
    #phone-app {
        position: relative;
        background-image: url('../img/phone-app-banner.png');
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 50px;
            width: 470px;
            height: 480px;
            background-image: url('../img/img-phone.png');
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 1;
            @include media-breakpoint-down(sm){
                display: none;
            }
        }
        .container {
            position: relative;
            z-index: 2;
        }
    }
    #collections-wrapper {
        background-color: $bg-color;
        width: 100%;
        @include media-breakpoint-down(sm) {
            .quote {
                padding-left: 0;
                padding-right: 0;
            }
            .quote-message {
                &::before {
                    left: -130px;
                    top: calc(50% - 150px);
                    font-size: 300px;
                }
                &::after {
                    right: -130px;
                    top: calc(50% - 150px);
                    font-size: 300px;
                }
            }
        }
    }
    #testimony-wrapper {
        background-image: url('../img/bg-testimony.png');
        background-size: cover;
        background-repeat: no-repeat;
        .slick-dots {
            bottom: 0;
        }
        @include media-breakpoint-down(sm) {
            .container {
                padding-left: 0;
                padding-right: 0;
            }
            .slick-dots {
                bottom: 100px;
            }
        }
    }
}