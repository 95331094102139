header{
    background-color: $bg-color;
    .container {
        display: flex;
        align-items: center;
        padding: 25px 15px;
        .site-logo {
            margin-right: 60px;
            a {
                font-family: $font-hepta-slab, sans-serif;
                font-size: 24px;
                font-weight: 800;
                display: flex;
                img {
                    margin-right: 15px;
                }
            }
        }
        .site-menus {
            .mobile-logo {
                a {
                    font-family: $font-hepta-slab, sans-serif;
                    font-size: 24px;
                    font-weight: 800;
                    display: flex;
                    img {
                        margin-right: 15px;
                    }
                }
            }
            ul {
                li {
                    &:before {
                        display: none !important;
                    }

                    a {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }

            @include media-breakpoint-down(sm){
                position: fixed;
                z-index: 13;
                background: white;
                padding: 50px 40px;
                width: 280px;
                right: -280px;
                height: 100vh;
                top: 0;
                transition: all .3s;

                &.active {
                    right: 0;
                }

                ul {
                    li {
                        margin-bottom: 30px;
                    }
                    &.site-actions {
                        display: inline-block;
                        text-align: right;
                        li {
                            text-align: center;
                        }
                    }
                }
            }
        }

        .burger-menu{
            color: $purple;
            position: relative;
            top: -6px;
            cursor: pointer;
            float: right;
            font-size: 40px;
            z-index: 10;
        }
    }
}

.mobile-header-overlay{
	position: fixed;
	z-index: 12;
	width: 100vh;
	top: 0;
	height: 100vh;
	background: $purple;
    opacity: 0.9;
	left: 0;
	display: none;
}
