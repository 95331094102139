.modal{
    position: fixed;
    overflow: auto;
    left: 50%;
    height: auto;
    width: 560px;
    top: 50%;
    display: none;
    margin-left: -200px;
    z-index: 22;
    &.modal-lg {
        width: 960px;
    }
    .modal-container{
        h3, h4{
            color: #000000;
            font-size: 30px;
        }
        .content-modal{
            padding: 0 24px;
            position: relative;
        }
    }
    .modal-floated-content{
        max-height: calc(100vh - 88px - 100px);
        overflow: auto;
    }
    &-no-padding{
        *{
            padding: 0 !important;
            line-height: 0;
        }
    }
    &-visible{
        overflow: visible;
    }
    @include media-breakpoint-down(sm) {
        display: none;
        width: 100% !important;
        min-width: 0 !important;
        left: 0 !important;
        top: 0 !important;
        position: fixed !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
        border-radius: 0 !important;
        height: 100vh !important;
        z-index: 11 !important;
        overflow: auto !important;
    }
}
.modal-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
    background-color: #000000;
    display: none;
    z-index: 10;
}
.trigger-modal{
    cursor: pointer;
}

#login-modal {
    a.forget-pwd {
        top: 15px;
    }
}