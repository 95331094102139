#collection {
    header {
        background-color: $primary-color;
        .site-logo {
            color: $white;
        }
        .site-menus {
            color: $white;
            @include media-breakpoint-down(sm){
                color: $purple;
            }
        }
        .container {
            border-color: rgba(255, 255, 255, 0.1);   
        }
        .burger-menu{
            color: $white;
        }
    }
    #collection-slider {
        .container {
            position: relative;
            .custom-slick-arrows {
                position: absolute;
                right: 0;
                left: 0;
                top: calc(50% - 25px);
            }
        }
        .slider {
            .slick-slide {
                .flex {
                    display: flex;
                }
            }
        }
    }

    #collection-story {
        background-image: url('../img/bg-testimony.png');
        background-size: cover;
        background-repeat: no-repeat;
        .short-line {
            width: 40px;
            height: 1px;
            margin: 19px auto;
            opacity: 0.2;
            background-color: $primary-color;
        }
        .quote-message {
            &::after, &::before {
                top: calc(50% - 140px);
            }
        }
        .slick-dots {
            bottom: 0;
        }
    }
}