#subscription {
    header {
        background-color: $primary-color;
        .site-logo {
            color: $white;
        }
        .site-menus {
            color: $white;
            @include media-breakpoint-down(sm){
                color: $purple;
            }
        }
        .container {
            border-color: rgba(255, 255, 255, 0.1);   
        }
        .burger-menu{
            color: $white;
        }
    }
    section {
        background-image: url('../img/phone-app-banner.png');
    }
    ul {
        li {
            &::before {
                font-family: '#{$icomoon-font-family}';
                font-style: normal;
                font-size: 24px;
                font-weight: 800;
                font-variant: normal;
                text-transform: none;
                content: $icon-play;
                margin-right: .5em;
                display: inline-block;
                position: absolute;
                left: -1.5em;
                color: $green;
                line-height: 1.3;
            }
        }
    }
}