.v-align-solo{
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.clamped{
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; 
	overflow: hidden;
}
.outline-none{
	outline: none;
	&:focus, &:active{
		outline: none;
	}
}
.no-lh{
	line-height: normal !important;
}
.fixed{
	position: fixed;
	top: 0;
	left: 0;
	background: white;
	width: 100%;
	z-index: 23;
}

.quote-message {
	position: relative;
	z-index: 11;
	overflow: hidden;
	&:before, &:after {
		font-family: $font-hepta-slab, sans-serif;
		position: absolute;
		color: white;
		font-size: 300px;
		font-weight: 900;
		line-height: 1;
		z-index: -1;
		top: calc(50% - 80px);
		@include media-breakpoint-down(sm){
			font-size: 300px;
		}
		@include media-breakpoint-down(sm) {
			top: calc(50% - 90px);
		}
	}
	&:before {
		content: '“';
		left: 0;
		@include media-breakpoint-down(sm) {
			left: -90px;
		}
	}
	&:after {
		content: '”';
		right: 0;
		@include media-breakpoint-down(sm) {
			right: -90px;
		}
	}
}