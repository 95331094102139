#register {
    .step-sidebar {
        width: 300px;
        margin-right: 100px;
        @include media-breakpoint-down(sm) {
            display: none;
        }
        ul {
            li {
                position: relative;
                cursor: pointer;
                font-size: 14px;
                line-height: 2.86;
                font-weight: 600;
                opacity: 0.6;
                &.active {
                    opacity: 1;
                    margin-left: 10px;
                    &:before {
                        content: '';
                        position: absolute;
                        left: -56px;
                        top: 4px;
                        display: block;
                        width: 44px;
                        height: 30px;
                        background-image: url('../img/img-selector.png');
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
    .step-content {
        width: calc(100% - 400px);
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    .step-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .prev-step-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
        }

        .step-progress-bar {
            width: 260px;
            @include media-breakpoint-down(sm) {
                width: 100px;
            }
            height: 8px;
            border-radius: 4px;
            position: relative;
            background-color: $white;
            @for $i from 1 to 6 {
                &-#{$i}{
                    &:after{
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-radius: 4px;
                        width: calc(#{$i} * (260px / 6));
                        @include media-breakpoint-down(sm) {
                            width: calc(#{$i} * (260px / 6));
                        }
                        height: 8px;
                        background-color: $green;
                    }
                }
            }
        }
    }
}